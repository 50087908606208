import $ from '../core/Dom';

export default el => {

    const $el = $(el);

    const listbox = $el.find('[role="listbox"]').get(0);
    const options = listbox.children;

    if (!options.length) {
        return;
    }

    const button = $el.find('button[aria-haspopup]').get(0);
    const value = $el.find('[data-value]').get(0);
    const placeholder = $el.find('[data-placeholder]').get(0);

    const $select = $el.find('select').eq(0);
    const $input = $el.find('input[type="hidden"]').eq(0);

    let selected = $(listbox).find('[aria-selected="true"]').get(0);
    let preventClick = false;

    const setValue = option => {
        $select.val(option);
        $input.val(option);
    };

    const select = option => {
        $(options).removeClass('selected deselected');
        if (selected) {
            selected.removeAttribute('aria-selected');
        }
        if (!option) {
            value.textContent = '';
            selected = null;
            setValue('');
            return;
        }
        option.setAttribute('aria-selected', 'true');
        value.textContent = option.textContent;
        selected = option;
        setValue(option.textContent);
    };

    const selectPrev = () => {
        const index = selected ? $(options).index(selected) - 1 : 0;
        select(options[index] || options[0]);
    };

    const selectNext = () => {
        const index = selected ? $(options).index(selected) + 1 : 0;
        select(options[index] || options[options.length - 1]);
    };

    const showListbox = () => {
        value.hidden = true;
        placeholder.hidden = false;
        listbox.hidden = false;
        button.setAttribute('aria-expanded', 'true');
        listbox.focus();
        el.classList.add('open');
    };

    const hideListbox = () => {
        listbox.hidden = true;
        value.hidden = !selected;
        placeholder.hidden = !!selected;
        button.removeAttribute('aria-expanded');
        button.focus();
        $(options).removeClass('selected deselected');
        el.classList.remove('open');
    };

    const onButtonKeyUp = e => {
        const key = e.key || e.which || e.keyCode;
        if (['ArrowDown', 'ArrowUp', 40, 38].indexOf(key) > -1) {
            if (!selected) {
                select(options[0]);
            }
            showListbox();
        }
    };

    const onButtonClick = () => {
        if (preventClick) {
            return;
        }
        showListbox();
    };

    const onListboxBlur = () => {
        preventClick = true;
        hideListbox();
        setTimeout(() => {
            preventClick = false;
        }, 100);
    };

    const onListboxKeyDown = e => {
        const key = e.key || e.which || e.keyCode;
        if (['ArrowDown', 40].indexOf(key) > -1) {
            e.preventDefault();
            selectNext();
        } else if (['ArrowUp', 38].indexOf(key) > -1) {
            e.preventDefault();
            selectPrev();
        } else if (['Enter', 'Escape', 13, 27].indexOf(key) > -1) {
            e.preventDefault();
            hideListbox();
        } else if (['End', 35].indexOf(key) > -1) {
            e.preventDefault();
            select(options[options.length - 1]);
        } else if (['Home', 36].indexOf(key) > -1) {
            e.preventDefault();
            select(options[0]);
        }
    };

    const onListboxClick = e => {
        if (e.target.getAttribute('role') !== 'option') {
            return;
        }
        select(e.target);
        hideListbox();
    };

    const onListboxMouseOver = e => {
        if (e.target.getAttribute('role') !== 'option') {
            return;
        }
        $(options).addClass('deselected').removeClass('selected');
        e.target.classList.add('selected');
    };

    const onKeyDown = e => {
        const key = e.key || e.which || e.keyCode;
        if (['ArrowDown', 'ArrowUp', 40, 38].indexOf(key) > -1) {
            e.preventDefault();
        }
    };

    const init = () => {
        el.addEventListener('keydown', onKeyDown);
        button.addEventListener('click', onButtonClick);
        button.addEventListener('keyup', onButtonKeyUp);
        listbox.addEventListener('blur', onListboxBlur);
        listbox.addEventListener('keydown', onListboxKeyDown);
        listbox.addEventListener('click', onListboxClick);
        listbox.addEventListener('mouseover', onListboxMouseOver);
    };

    const destroy = () => {
        el.removeEventListener('keydown', onKeyDown);
        button.removeEventListener('click', onButtonClick);
        button.removeEventListener('keyup', onButtonKeyUp);
        listbox.removeEventListener('blur', hideListbox);
        listbox.removeEventListener('keydown', onListboxKeyDown);
        listbox.removeEventListener('click', onListboxClick);
        listbox.removeEventListener('mouseover', onListboxMouseOver);
    };

    return {
        init,
        destroy
    };

};
