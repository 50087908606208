import $ from '../core/Dom';

export default el => {

    const $el = $(el);
    const toggleButton = $el.find('button.supports\\:clamp').get(0);

    // No toggle button or toggle button is not visible
    if (!toggleButton || toggleButton.offsetParent === null) {
        return null;
    }

    const textDiv = $el.find('[class*="lines"]').get(0);
    const clampClasses = textDiv ? Array.from(textDiv.classList).filter(className => className === 'clamp' || className.indexOf('lines-') > -1) : [];

    console.log({clampClasses});

    if (!textDiv || !clampClasses.length) {
        return null;
    }

    let isClamped = true;

    const toggleClamp = () => {
        if (isClamped) {
            $(textDiv).removeClass(clampClasses.join(' '));
        } else {
            $(textDiv).addClass(clampClasses.join(' '));
        }
        $(toggleButton).find('span').each(span => {
            span.hidden = !span.hidden;
        });
        isClamped = !isClamped;
    };

    const init = () => {
        $(toggleButton).on('click', toggleClamp);
    };

    const destroy = () => {
        $(toggleButton).off('click');
    };

    return {
        init,
        destroy
    };

};
