import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    const $el = $(el);
    const slider = $el.find('[data-slides]').get(0);
    const slides = slider.children;

    let index = -1;
    let tween;

    const next = () => {
        index += 1;
        if (index > slides.length - 1) {
            // Reset
            gsap.set(slides, { clearProps: 'order' });
            index = 0;
        }
        const nextIndex = index < slides.length - 1 ? index + 1 : 0;
        slides[nextIndex].hidden = false;
        tween = gsap.timeline({
            delay: 2,
            onComplete: next
        })
            .to(slider, {
                xPercent: -100,
                duration: 2,
                ease: 'Quint.easeInOut'
            }, 'x')
            .add(() => {
                $el.find('[data-dot]').removeClass('is-active').eq(nextIndex).addClass('is-active');
            }, 'x+=1')
            .set(slides[index], { order: slides.length + 1 })
            .set(slider, { xPercent: 0 });
    };

    const init = () => {
        next();
    };

    const destroy = () => {
        if (tween) {
            tween.kill();
        }
    };

    return {
        init,
        destroy
    };

};
