import $ from '../core/Dom';
import gsap from 'gsap';

export default el => {

    const $el = $(el);

    const onToggleClick = e => {
        const { triggerTarget: toggle } = e;
        const target = $el.find(`#${toggle.getAttribute('aria-controls')}`).get(0);
        const caret = $(toggle).find('svg').get(0);
        if (toggle.getAttribute('aria-expanded') === 'true') {
            // Close it
            toggle.setAttribute('aria-expanded', 'false');
            gsap.set(caret, { rotate: 0 });
            target.hidden = true;
        } else {
            // Open it
            toggle.setAttribute('aria-expanded', 'true');
            target.hidden = false;
            gsap.set(caret, { rotate: 180 });
        }
    };

    const init = () => {
        $el.on('click', 'button[aria-expanded]', onToggleClick);
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
