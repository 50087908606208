import axios from 'axios';
import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import Components from '../core/Components';

import {
    DRILLDOWN_BEFORE_OPEN, DRILLDOWN_AFTER_OPEN, DRILLDOWN_BEFORE_CLOSE, DRILLDOWN_AFTER_CLOSE
} from '../lib/events';

export default el => {

    const $el = $(el);
    const dialog = el.firstElementChild;
    const container = $el.find('[data-drilldown-container]').get(0);
    const wrapper = $el.find('[data-drilldown-wrapper]').get(0);
    const content = $el.find('[data-drilldown-content]').get(0);
    const closeBtn = $el.find('[data-drilldown-close]').get(0);

    const cache = {};

    let isOpen = false;
    let isVisible = false;
    let focusedElementBeforeOpen;
    let observer;
    let tl;

    const fetchDrilldownHtml = url => {

        if (cache[url]) {
            return new Promise(resolve => {
                resolve(cache[url]);
            });
        }

        return axios
            .get(url, {
                headers: { 'X-Requested-With': 'XMLHttpRequest' }
            })
            .then(({
                status,
                data
            }) => {
                if (status !== 200 || !data) {
                    throw new Error();
                }
                cache[url] = data;
                return data;
            })
            .catch(error => {
                console.error(error);
            });

    };

    const preload = () => {
        const drilldownUrls = $('a[data-drilldown]').get().map(link => link.href);
        drilldownUrls.forEach(url => {
            fetchDrilldownHtml(url);
        });
    };

    const reset = () => {
        Components.destroy(content);
        $(content).html('');
    };

    const setContent = html => {
        if (!isOpen) {
            return;
        }
        $(content).html(html);
        Components.init(content);
        gsap.fromTo(content, { opacity: 0 }, { opacity: 1, duration: 0.5, ease: 'Cubic.easeIn' });
    };

    const close = (tween = true) => {
        if (!isOpen) {
            return;
        }

        isOpen = false;

        Dispatch.emit(DRILLDOWN_BEFORE_CLOSE);

        Viewport.releaseTabbing(focusedElementBeforeOpen);

        const afterClose = () => {
            tl = null;
            focusedElementBeforeOpen = null;
            dialog.hidden = true;
            dialog.removeAttribute('aria-label');
            reset();
            Dispatch.emit(DRILLDOWN_AFTER_CLOSE);
        };

        if (tl) {
            tl.kill();
        }

        if (!tween) {
            afterClose();
            return;
        }

        el.classList.add('is-closing');

        tl = gsap.timeline({
            onComplete: afterClose
        })
            .to(wrapper, { xPercent: 100, duration: 0.75, ease: 'Quad.easeOut' }, 0)
            .to(closeBtn, { x: 60, duration: 0.3, ease: 'Quad.easeOut' }, 0)
            .to(closeBtn, { opacity: 0, duration: 0.3 }, 0);

    };

    const open = url => {

        if (isOpen || !isVisible) {
            return;
        }

        isOpen = true;

        Dispatch.emit(DRILLDOWN_BEFORE_OPEN, { url });
        focusedElementBeforeOpen = document.activeElement;

        el.classList.remove('is-closing');
        dialog.hidden = false;

        if (tl) {
            tl.kill();
        }

        tl = gsap.timeline({
            onComplete: () => {
                tl = null;
            }
        })
            .fromTo(wrapper, { xPercent: 100 }, { xPercent: 0, duration: 0.75, ease: 'Quint.easeOut' }, 0)
            .fromTo(content, { xPercent: 10 }, { xPercent: 0, duration: 1.5, ease: 'Quint.easeOut' }, 0.3)
            .fromTo(closeBtn, { x: 200 }, { x: 0, duration: 0.75, ease: 'Quint.easeOut' }, 0.5)
            .fromTo(closeBtn, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0.5);

        Viewport.lockTabbing(dialog, closeBtn);

        Dispatch.emit(DRILLDOWN_AFTER_OPEN, { url });

        fetchDrilldownHtml(url).then(data => {
            setContent(data);
        });
    };

    const onDrilldownLinkClick = e => {
        if (!isVisible || isOpen) {
            return;
        }
        e.preventDefault();
        dialog.setAttribute('aria-label', e.target.dataset.drilldown || e.target.textContent);
        open(e.target.href);
    };

    const onCloseBtnClick = () => {
        close();
    };

    const onBodyKeyUp = e => {
        const key = e.key || e.which;
        if (key === 'Escape' || key === 27) {
            close();
        }
    };

    const onBodyClick = e => {
        if (!isOpen || e.target.hasAttribute('data-drilldown') || e.target.hasAttribute('data-drilldown-container') || e.target === container || e.target === closeBtn || container.contains(e.target)) {
            return;
        }
        close();
    };

    const init = () => {
        $('body')
            .on('click', 'a[data-drilldown]', onDrilldownLinkClick)
            .on('click', onBodyClick)
            .on('keyup', onBodyKeyUp);
        $(closeBtn).on('click', onCloseBtnClick);
        // Use intersection observer to close the drilldown panel if it is made hidden whilst open
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            isVisible = isIntersecting;
            if (!isVisible) {
                close(false);
            } else {
                requestAnimationFrame(preload);
            }
        });
        observer.observe(el);
    };

    const destroy = () => {
        $('body')
            .off('click', onDrilldownLinkClick)
            .off('click', onBodyClick)
            .off('keyup', onBodyKeyUp);
        $(closeBtn).off('click');
        observer.disconnect();
        close(false);
    };

    return {
        init,
        destroy
    };

};
