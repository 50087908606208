import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';

export default el => {

    const $el = $(el);
    const $filters = $el.find('[data-filters]').eq(0);

    if (!$filters.length) {
        return;
    }

    const liveHeading = $el.find('h3[aria-live]').get(0);

    const listItems = $el.find('li[data-locations]');

    const onFilterClick = e => {
        const { triggerTarget: button } = e;
        if (button.getAttribute('aria-pressed') === 'true') {
            return;
        }
        $filters.find('[aria-pressed="true"]').attr({ 'aria-pressed': 'false' });
        button.setAttribute('aria-pressed', 'true');
        const location = button.dataset.id;
        listItems.each(item => {
            if (!location) {
                item.hidden = false;
            } else {
                item.hidden = item.dataset.locations.split(',').filter(value => !!value).indexOf(location) === -1;
            }
        });
        liveHeading.textContent = button.dataset.desc;
    };

    const init = () => {
        $filters.on('click', 'button[aria-pressed]', onFilterClick);
    };

    const destroy = () => {
        $filters.off('click');
    };

    return {
        init,
        destroy
    };

};
