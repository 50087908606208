import { getScrollbarWidth } from '../core/utils';
import Viewport from '../core/Viewport';

let scrollbarWidth = null;

export const lockScroll = () => {
    if (scrollbarWidth === null) {
        scrollbarWidth = getScrollbarWidth();
    }
    Viewport.lockScrolling();
    if (scrollbarWidth) {
        document.documentElement.style.paddingRight = `${scrollbarWidth}px`;
    }
};

export const unlockScroll = () => {
    Viewport.releaseScrolling();
    document.documentElement.style.paddingRight = '';
};
