import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { DRILLDOWN_BEFORE_CLOSE, DRILLDOWN_BEFORE_OPEN } from '../lib/events';

const loadAnim = require('bundle-loader?lazy&name=technology.anim.min!../vendor/technology.anim.min.js');

export default el => {

    const $el = $(el);
    const panels = $el.find('[data-panel]').get();
    const animEl = $el.find('[data-anim]').get(0);
    const legend = $el.find('[data-legend]').get(0);

    let anim = null;
    let panelObserver;
    let animObserver;
    let activePanelIndex = -1;
    let activeAnimChapter = -1;
    let animChapterDelay;

    const resumeAnim = () => {
        if (!anim || !anim.isPaused()) {
            return;
        }
        anim.setPaused(false);
    };

    const pauseAnim = () => {
        if (!anim || anim.isPaused()) {
            return;
        }
        anim.setPaused(true);
    };

    const setActiveAnimationChapter = () => {

        if (!anim || activePanelIndex < 0) {
            return;
        }

        const chapters = ['0', '2', '3'];
        const chapter = parseInt(chapters[activePanelIndex] || chapters[chapters.length - 1], 10);

        if (chapter === activeAnimChapter) {
            return;
        }

        activeAnimChapter = chapter;

        if (animChapterDelay) {
            clearTimeout(animChapterDelay);
        }

        animChapterDelay = setTimeout(() => {
            anim.setChapter(activeAnimChapter);
            clearTimeout(animChapterDelay);
            animChapterDelay = null;
        }, 500);

    };

    const setActivePanel = panel => {

        const panelIndex = parseInt(panel.dataset.panel, 10);
        if (panelIndex === activePanelIndex) {
            return;
        }

        activePanelIndex = panelIndex;
        const adjacentPanelIndex = activePanelIndex - (activePanelIndex > 0 ? 1 : -1);

        $el.find('[data-legend]').removeClass('is-active is-adjacent');
        $el.find(`[data-legend="${activePanelIndex}"]`).addClass('is-active');
        $el.find(`[data-legend="${adjacentPanelIndex}"]`).addClass('is-adjacent');

        setActiveAnimationChapter();

    };

    const jumpToPanel = panel => {
        Viewport.scrollTo(panel);
    };

    const onPanelObserve = entries => {
        entries.forEach(entry => {
            const { target, isIntersecting } = entry;
            if (isIntersecting) {
                setActivePanel(target);
            }
        });
    };

    const onAnimObserve = ([{ isIntersecting }]) => {
        if (isIntersecting) {
            if (anim === null) {
                anim = false;
                // Init the animation
                loadAnim(() => {
                    try {
                        anim = window.solutionseeker.create(animEl);
                        setActiveAnimationChapter();
                    } catch (error) {
                        console.error(error);
                    }
                });
            } else {
                resumeAnim();
            }
        } else if (!isIntersecting && anim) {
            pauseAnim();
        }
    };

    const onAnchorClick = e => {
        const href = $(e.triggerTarget).attr('href');
        const target = $el.find(href).get(0);
        if (!target || !panels.includes(target)) {
            return;
        }
        e.preventDefault();
        jumpToPanel(target);
    };

    const onDrilldownOpen = () => {
        pauseAnim();
        gsap.timeline()
            .to(panels, {
                x: -20,
                duration: 0.3,
                ease: 'Quad.easeIn'
            }, 0)
            .to(panels, {
                opacity: 0,
                duration: 0.3,
                ease: 'Sine.easeIn'
            }, 0);
    };

    const onDrilldownClose = () => {
        resumeAnim();
        gsap.timeline({ delay: 0.2 })
            .to(panels, {
                x: 0,
                duration: 1,
                ease: 'Quint.easeOut'
            }, 0)
            .to(panels, {
                opacity: 1,
                duration: 0.3,
                ease: 'Sine.easeIn'
            }, 0);
    };

    const onResize = () => {
        if (legend.offsetParent === null) {
            // Legend is hidden, this is irrelevant
            return;
        }
        const firstPanel = panels[0];
        const firstPanelTextOffset = $(firstPanel).find('[data-text]').get(0).getBoundingClientRect().top - firstPanel.getBoundingClientRect().top;
        const otherPanels = panels.slice(1);
        otherPanels.forEach(otherPanel => {
            const otherPanelText = $(otherPanel).find('[data-text]').get(0);
            const otherPanelTextOffset = otherPanelText.getBoundingClientRect().top - otherPanel.getBoundingClientRect().top;
            gsap.set(otherPanelText.firstElementChild, { top: firstPanelTextOffset - otherPanelTextOffset });
        });
        gsap.set(legend.firstElementChild, { clearProps: 'all' });
        const legendOffset = legend.getBoundingClientRect().top - legend.parentNode.getBoundingClientRect().top;
        gsap.set(legend.firstElementChild, { top: firstPanelTextOffset - legendOffset });
    };

    const init = () => {
        panelObserver = new IntersectionObserver(onPanelObserve, {
            threshold: 0.5
        });
        panels.forEach(panel => {
            panelObserver.observe(panel);
        });
        animObserver = new IntersectionObserver(onAnimObserve);
        animObserver.observe(animEl);
        $el.on('click', 'a[href^="#"]', onAnchorClick);
        Viewport.on('resize', onResize);
        Dispatch.on(DRILLDOWN_BEFORE_OPEN, onDrilldownOpen);
        Dispatch.on(DRILLDOWN_BEFORE_CLOSE, onDrilldownClose);
        onResize();
    };

    const destroy = () => {
        if (panelObserver) {
            panelObserver.disconnect();
        }
        if (animObserver) {
            animObserver.disconnect();
        }
        if (animChapterDelay) {
            clearTimeout(animChapterDelay);
        }
        $el.off('click');
        Viewport.off('resize', onResize);
        Dispatch.off(DRILLDOWN_BEFORE_OPEN, onDrilldownOpen);
        Dispatch.off(DRILLDOWN_BEFORE_CLOSE, onDrilldownClose);
    };

    return {
        init,
        destroy
    };

};
