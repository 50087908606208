import $ from '../core/Dom';

export default el => {

    const target = el.nextElementSibling;

    const siblings = $(`[data-component="${el.getAttribute('data-component')}"]:not([aria-controls="${el.getAttribute('aria-controls')}"])`).get();

    let isExpanded = false;

    const isTouch = () => document.documentElement.classList.contains('using-touch');

    const isKeys = () => document.documentElement.classList.contains('outline');

    const showBio = () => {
        if (isExpanded) {
            return;
        }
        isExpanded = true;
        el.setAttribute('aria-expanded', 'true');
        el.setAttribute('tabIndex', '-1');
        target.hidden = false;
        siblings.forEach(sibling => {
            $(sibling).data('biotoggle').hideBio();
        });
    };

    const hideBio = () => {
        if (!isExpanded) {
            return;
        }
        isExpanded = false;
        el.setAttribute('aria-expanded', 'false');
        el.removeAttribute('tabIndex');
        target.hidden = true;
    };

    const toggleBio = () => {
        if (!isExpanded) {
            showBio();
        } else {
            hideBio();
        }
    };

    const onClick = () => {
        if (!isTouch()) {
            return;
        }
        toggleBio();
    };

    const onMouseOver = () => {
        if (isTouch()) {
            return;
        }
        showBio();
    };

    const onTargetMouseLeave = () => {
        if (isTouch()) {
            return;
        }
        hideBio();
    };

    const onFocusIn = () => {
        if (!isKeys()) {
            return;
        }
        showBio();
        target.focus();
    };

    const onTargetBlur = () => {
        if (!isExpanded) {
            return;
        }
        requestAnimationFrame(() => {
            if (target !== document.activeElement && !target.contains(document.activeElement)) {
                hideBio();
            }
        });
    };

    const init = () => {
        el.addEventListener('click', onClick);
        el.addEventListener('mouseenter', onMouseOver);
        el.addEventListener('focusin', onFocusIn);
        target.addEventListener('mouseleave', onTargetMouseLeave);
        target.addEventListener('focusout', onTargetBlur);
    };

    const destroy = () => {
        el.removeEventListener('click', onClick);
        el.removeEventListener('mouseenter', onMouseOver);
        el.removeEventListener('focusin', onFocusIn);
        target.removeEventListener('mouseleave', onTargetMouseLeave);
        target.removeEventListener('focusout', onTargetBlur);
        hideBio();
    };

    const api = {
        init,
        destroy,
        hideBio
    };

    $(el).data('biotoggle', api);

    return api;

};
