import Set from 'es6-set';
import gsap from 'gsap';
import debounce from 'lodash/debounce';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { lockScroll, unlockScroll } from '../lib/helpers';

export default el => {

    const $el = $(el);

    const menuBtn = $el.find('button[aria-expanded]').get(0);
    const menu = menuBtn.nextElementSibling;
    const burger = $el.find('[data-burger]').get(0);
    const burgerBars = Array.from(burger.children);

    const bodyIsDark = document.body.classList.contains('bg-dark');
    const intersectingLightNDarks = new Set();

    let isMenuOpen = false;
    let activeElBeforeMenuOpen = null;

    const maybeInvertBurgerBars = () => {

        const nodes = [Array.from(intersectingLightNDarks)][0];
        const { y: burgerY, height: burgerHeight, right: burgerRight } = burger.getBoundingClientRect();

        const barsToMakeDark = [];
        const barsToMakeLight = [];

        for (let i = 0; i < nodes.length; i += 1) {
            const { right: nodeRight, y: nodeY, height: nodeHeight } = nodes[i].getBoundingClientRect();
            const nodeIntersectingBurger = nodeY <= (burgerY + burgerHeight) && (nodeY + nodeHeight) >= burgerY && Math.ceil(nodeRight) >= burgerRight;
            if (nodeIntersectingBurger) {
                const isDark = nodes[i].dataset.hasOwnProperty('dark');
                burgerBars.forEach(bar => {
                    const { y: lineY, height: lineHeight } = bar.getBoundingClientRect();
                    const barIntersecting = nodeY <= lineY + (lineHeight / 2) && nodeY + nodeHeight >= lineY;
                    if (!barIntersecting) {
                        return;
                    }
                    if (isDark) {
                        barsToMakeDark.push(bar);
                    } else {
                        barsToMakeLight.push(bar);
                    }
                });
                break;
            }
        }

        barsToMakeDark.forEach(bar => {
            bar.classList.remove('bg-current', 'bg-dark');
            bar.classList.add('bg-white');
        });

        barsToMakeLight.forEach(bar => {
            bar.classList.remove('bg-current', 'bg-white');
            bar.classList.add('bg-dark');
        });

        const barsToReset = burgerBars.filter(bar => barsToMakeLight.indexOf(bar) === -1 && barsToMakeDark.indexOf(bar) === -1);
        if (barsToReset.length) {
            barsToReset.forEach(bar => {
                if (bodyIsDark) {
                    bar.classList.remove('bg-dark');
                    bar.classList.add('bg-white');
                } else {
                    bar.classList.remove('bg-white');
                    bar.classList.add('bg-dark');
                }
            });
        }

    };

    const maybeInvertBurgerBarsDeferred = debounce(maybeInvertBurgerBars, 10, {leading: false, trailing: true});

    const lightNDarksObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const { isIntersecting, target } = entry;
            if (isIntersecting) {
                intersectingLightNDarks.add(target);
            } else {
                intersectingLightNDarks.delete(target);
            }
            maybeInvertBurgerBarsDeferred();
        });
    }, {
        rootMargin: '0px',
        threshold: 0
    });

    $('[data-light],[data-dark]').each(node => {
        lightNDarksObserver.observe(node);
    });

    const closeMenu = (tween = true) => {
        if (!isMenuOpen) {
            return;
        }
        const afterClose = () => {
            menu.hidden = true;
        };
        isMenuOpen = false;
        menuBtn.setAttribute('aria-expanded', false);
        Viewport.releaseTabbing(activeElBeforeMenuOpen || menuBtn);
        unlockScroll();
        activeElBeforeMenuOpen = null;
        if (!tween) {
            afterClose();
            return;
        }
        gsap.to(menu, {
            opacity: 0,
            duration: 0.3,
            onComplete: afterClose
        });
    };

    const openMenu = (tween = true) => {
        if (isMenuOpen) {
            return;
        }
        isMenuOpen = true;
        menuBtn.setAttribute('aria-expanded', true);
        menu.hidden = false;
        activeElBeforeMenuOpen = document.activeElement || menuBtn;
        Viewport.lockTabbing(menu.parentNode, menuBtn);
        lockScroll();
        if (!tween) {
            gsap.set(menu, { opacity: 1 });
            return;
        }
        const links = $(menu).find('[data-wrapper] a').get();
        gsap.timeline()
            .fromTo(menu, {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0.3
            }, 0)
            .fromTo(links, {
                x: -50
            }, {
                x: 0,
                duration: 1,
                stagger: 0.01,
                ease: 'Quint.easeOut'
            }, 0)
            .fromTo(links, {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0.5,
                stagger: 0.01
            }, 0);
    };

    const toggleMenu = e => {
        if (isMenuOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    };

    const onBodyKeyUp = e => {
        if (!isMenuOpen) {
            return;
        }
        const key = e.key || e.keyCode || e.which;
        if (key === 'Escape' || key === 27) {
            closeMenu();
        }
    };

    const onScroll = () => {
        maybeInvertBurgerBarsDeferred();
    };

    const init = () => {
        menuBtn.addEventListener('click', toggleMenu);
        document.body.addEventListener('keyup', onBodyKeyUp);
        // Account for the menu being opened already before the JS had the chance to boot
        if (window.location.hash === '#menu') {
            requestAnimationFrame(() => {
                openMenu(false);
                window.location.hash = '';
                window.history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
            });
        }
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', () => {
            setTimeout(onScroll, 0);
        });
    };

    const destroy = () => {
        closeMenu(false);
        menuBtn.removeEventListener('click', toggleMenu);
        document.body.removeEventListener('keyup', onBodyKeyUp);
        lightNDarksObserver.disconnect();
    };

    return {
        init,
        destroy
    };

};
