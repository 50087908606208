import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {

    const $el = $(el);

    const numbers = $el.find('button[data-number]').get();
    const numbersParent = numbers[0].parentNode;

    let interval = parseFloat(el.dataset.interval || 5) * 1000;

    if (interval < 3000) {
        interval = 3000;
    } else if (interval > 10000) {
        interval = 10000;
    }

    let userHasInteracted = false;
    let initObserver;
    let intervalTimeout;
    let setActiveNumberHandler;
    let tl;

    const getActiveNumber = () => $el.find('[data-number][aria-expanded="true"]').get(0);

    const destroyInterval = () => {
        if (!intervalTimeout) {
            return;
        }
        clearTimeout(intervalTimeout);
        intervalTimeout = null;
    };

    const maybeCreateInterval = () => {
        destroyInterval();
        if (userHasInteracted || numbers.length <= 1) {
            return;
        }
        intervalTimeout = setTimeout(() => {
            const activeNumberIndex = parseInt(getActiveNumber().dataset.number, 10);
            const newActiveNumber = numbers[activeNumberIndex + 1] || numbers[0];
            setActiveNumberHandler(newActiveNumber);
        }, interval);
    };

    const setActiveNumber = (newActiveNumber, force = false, duration = 0.75) => {

        destroyInterval();

        if (!newActiveNumber || !numbersParent.offsetParent) {
            return;
        }

        if (!force && newActiveNumber.getAttribute('aria-expanded') === 'true') {
            return;
        }

        if (tl) {
            tl.kill();
        }

        const ease = 'Quad.easeInOut';

        gsap.killTweensOf([numbersParent].concat(numbers));

        const newActiveNumberIndex = parseInt(newActiveNumber.dataset.number, 10);
        const isFirstNumber = newActiveNumberIndex === 0;
        const isLastNumber = !isFirstNumber && newActiveNumberIndex >= numbers.length - 1;
        const siblingNumbers = numbers.filter(number => parseInt(number.dataset.number, 10) !== newActiveNumberIndex);

        const numberFromWidths = new WeakMap();

        numbers.forEach(number => {
            numberFromWidths.set(number, number.getBoundingClientRect().width);
        });

        siblingNumbers.forEach(number => {
            number.setAttribute('aria-expanded', 'false');
            number.nextElementSibling.hidden = true;
        });

        newActiveNumber.setAttribute('aria-expanded', 'true');
        newActiveNumber.nextElementSibling.hidden = false;

        gsap.set(numbers, { clearProps: 'width' });

        const { left: numbersParentLeft } = numbersParent.getBoundingClientRect();
        const { width: siblingNumberToWidth = 150 } = siblingNumbers.length ? siblingNumbers[0].getBoundingClientRect() : {};
        const { width: newActiveNumberToWidth, left: newActiveNumberLeft } = newActiveNumber.getBoundingClientRect();

        // Figure out the offset
        let wrapperX = 0;

        if (!isFirstNumber) {
            const newActiveNumberOffsetLeft = newActiveNumberLeft - numbersParentLeft;
            const newActiveNumberRightBound = newActiveNumberOffsetLeft + newActiveNumberToWidth;
            const maxRightBound = Viewport.width - (!isLastNumber ? (siblingNumberToWidth * 0.5) : 0);
            if (newActiveNumberRightBound > maxRightBound) {
                wrapperX = maxRightBound - newActiveNumberRightBound;
            }
            if (!isLastNumber) {
                const maxLeftBound = (newActiveNumberLeft - (siblingNumberToWidth * 0.15)) - (numbersParentLeft - wrapperX);
                if (maxLeftBound < 0) {
                    wrapperX -= maxLeftBound;
                }
            }
        }

        tl = gsap.timeline();

        siblingNumbers.forEach(number => {
            tl.fromTo(number, {
                width: numberFromWidths.get(number)
            }, {
                width: siblingNumberToWidth,
                duration,
                ease
            }, 0);
        });

        const siblingNumberTexts = $(siblingNumbers).find('[data-text]').get();
        if (siblingNumberTexts.length) {
            tl.to(siblingNumberTexts, {
                opacity: 0,
                duration: 0.3
            }, 0);
        }

        tl
            .fromTo(newActiveNumber, {
                width: numberFromWidths.get(newActiveNumber)
            }, {
                width: newActiveNumberToWidth,
                duration,
                ease
            }, 0)
            .to($(newActiveNumber).find('[data-text]').get(0), {
                opacity: 1,
                duration: duration * 0.5
            }, duration * 0.95);

        const numberIsOdd = !!(newActiveNumberIndex % 2);
        const text = newActiveNumber.nextElementSibling.textContent;

        const $captions = $el.find('[data-caption]');
        const activeCaption = !numberIsOdd ? $captions.get(1) : $captions.get(0);
        const inactiveCaption = !numberIsOdd ? $captions.get(0) : $captions.get(1);

        gsap.killTweensOf([activeCaption, inactiveCaption]);
        gsap.set([activeCaption, inactiveCaption], { clearProps: 'width' });

        const { width: activeCaptionFromWidth } = activeCaption.getBoundingClientRect();
        const { width: inactiveCaptionFromWidth } = inactiveCaption.getBoundingClientRect();

        activeCaption.classList.add('is-active');
        inactiveCaption.classList.remove('is-active');

        const inactiveText = inactiveCaption.firstElementChild.textContent;

        activeCaption.firstElementChild.textContent = text;
        inactiveCaption.firstElementChild.textContent = '';

        const { width: activeCaptionToWidth } = activeCaption.getBoundingClientRect();
        const { width: inactiveCaptionToWidth } = inactiveCaption.getBoundingClientRect();

        inactiveCaption.firstElementChild.textContent = inactiveText;

        tl
            .fromTo(activeCaption, {
                width: activeCaptionFromWidth
            }, {
                width: activeCaptionToWidth,
                duration,
                ease
            }, 0)
            .fromTo(activeCaption.firstElementChild, {
                opacity: 0
            }, {
                opacity: 1,
                duration: duration * 0.5
            }, duration * 0.95)
            .fromTo(inactiveCaption, {
                width: inactiveCaptionFromWidth
            }, {
                width: inactiveCaptionToWidth,
                duration,
                ease,
                onComplete() {
                    inactiveCaption.firstElementChild.textContent = '';
                }
            }, 0)
            .to(inactiveCaption.firstElementChild, {
                opacity: 0,
                duration: duration * 0.5
            }, 0);

        tl.to(numbersParent, { x: wrapperX, duration, ease }, 0);

        tl.set(numbers.concat([activeCaption, inactiveCaption]), { clearProps: 'width' }, duration);

        maybeCreateInterval();

        console.info('active number set');

    };

    setActiveNumberHandler = setActiveNumber;

    const maybeShortenNumbers = () => {
        const { name: breakpoint } = Viewport.breakpoint;
        const isSmall = ['m', 'l', 'lp', 'xl'].indexOf(breakpoint) === -1;
        const padding = isSmall ? 50 : 300;
        const maxWidth = Viewport.width - padding;
        $el.find('[data-number]').each(number => {
            const long = $(number).find('[data-long]').get(0);
            const short = $(number).find('[data-short]').get(0);
            if (!long || !short) {
                return;
            }
            long.hidden = false;
            short.hidden = true;
            const { width } = long.getBoundingClientRect();
            if (width > maxWidth) {
                long.hidden = true;
                short.hidden = false;
            }
        });
    };

    const onNumberClick = e => {
        userHasInteracted = true;
        setActiveNumber(e.triggerTarget);
    };

    const onResize = () => {
        maybeShortenNumbers();
        if (!numbersParent.offsetParent) {
            destroyInterval();
            return;
        }
        setActiveNumber(getActiveNumber(), true, 0);
    };

    const init = () => {
        $el.on('click', 'button[data-number]', onNumberClick);
        Viewport.on('resize', onResize);
        maybeShortenNumbers();
        initObserver = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                userHasInteracted = false; // Reset this to restart the timeout if the user scrolls back in for EXTRA EFFECT
                destroyInterval();
                return;
            }
            if (getActiveNumber()) {
                maybeCreateInterval();
                return;
            }
            setTimeout(() => {
                setActiveNumber(numbers[0]);
            }, 300);
        });
        initObserver.observe(numbersParent);
        gsap.set(el.firstElementChild, { opacity: 1 });
    };

    const destroy = () => {
        $el.off('click');
        Viewport.off('resize', onResize);
        destroyInterval();
        if (initObserver) {
            initObserver.disconnect();
            initObserver = null;
        }
    };

    return {
        init,
        destroy
    };

};
