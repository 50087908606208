import Flickity from 'flickity';
import Viewport from '../core/Viewport';

export default el => {

    let flkty;

    const destroyFlickity = () => {
        if (!flkty) {
            return;
        }
        flkty.destroy();
        flkty = null;
    };

    const createFlickity = () => {
        destroyFlickity();
        flkty = new Flickity(el, {
            contain: false,
            dragThreshold: 15,
            cellAlign: 'left',
            prevNextButtons: false,
            pageDots: false,
            freeScroll: false,
            freeScrollFriction: 0.045,
            setGallerySize: false,
            percentPosition: true,
            resize: true,
            wrapAround: false,
            accessibility: true,
            on: {
                dragStart() {
                    document.ontouchmove = e => e.preventDefault();
                },
                dragEnd() {
                    document.ontouchmove = () => true;
                }
            }
        });
    };

    const onBreakpoint = () => {
        const { name: breakpoint } = Viewport.breakpoint;
        const isSmall = ['m', 'mp', 'l', 'lp', 'xl'].indexOf(breakpoint) === -1;
        if (isSmall && !flkty) {
            createFlickity();
        } else if (!isSmall && !!flkty) {
            destroyFlickity();
        }
        console.log({ breakpoint });
    };

    const init = () => {
        onBreakpoint();
        Viewport.on('breakpoint', onBreakpoint);
    };

    const destroy = () => {
        destroyFlickity();
    };

    return {
        init,
        destroy
    };

};
