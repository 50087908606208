import Flickity from 'flickity';
import $ from '../core/Dom';

export default el => {

    const $el = $(el);
    const slider = el.firstElementChild;
    const nextBtn = $el.find('button[data-next]').get(0);
    const prevBtn = $el.find('button[data-prev]').get(0);
    const numSlides = slider.children.length;

    let flkty;

    const updateNextPrev = () => {
        const { selectedIndex } = flkty;
        prevBtn.disabled = !selectedIndex;
        nextBtn.disabled = selectedIndex >= numSlides - 1;
    };

    const updateCaption = () => {
        const $caption = $(flkty.selectedCell.element).find('[data-caption]').eq(0);
        $(el).find('[data-caption]').last().html($caption.html() || '');
    };

    const updateCounter = () => {
        const { selectedIndex } = flkty;
        $el.find('[data-index]').get(0).textContent = selectedIndex + 1;
    };

    const destroyFlickity = () => {
        if (!flkty) {
            return;
        }
        flkty.destroy();
        flkty = null;
    };

    const createFlickity = () => {
        destroyFlickity();
        flkty = new Flickity(slider, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            prevNextButtons: false,
            pageDots: false,
            freeScroll: false,
            freeScrollFriction: 0.045,
            setGallerySize: false,
            percentPosition: true,
            resize: true,
            wrapAround: false,
            accessibility: true,
            on: {
                dragStart() {
                    document.ontouchmove = e => e.preventDefault();
                },
                dragEnd() {
                    document.ontouchmove = () => true;
                },
                change() {
                    updateCounter();
                    updateCaption();
                    updateNextPrev();
                }
            }
        });
        if (nextBtn && prevBtn) {
            $(nextBtn).on('click', () => {
                flkty.next();
            });
            $(prevBtn).on('click', () => {
                flkty.previous();
            });
        }
    };

    const init = () => {
        createFlickity();
    };

    const destroy = () => {
        destroyFlickity();
        if (nextBtn && prevBtn) {
            $([nextBtn, prevBtn]).off('click');
        }
    };

    return {
        init,
        destroy
    };

};
