import serialize from 'form-serialize';
import $ from '../core/Dom';
import request from '../core/request';

export default form => {

    const $form = $(form);
    const errorsList = $form.find('ul[data-errors]').get(0);
    const errorMessage = $form.find('[data-error]').get(0);

    let submitBtn = $form.find('button[type="submit"]').get(0);
    let isSubmitting = false;

    const showReceipt = html => {
        submitBtn = null;
        $form.html(html);
        const receipt = $form.find('[data-receipt]').get(0);
        if (receipt) {
            receipt.focus();
        }
    };

    const showErrorMessage = () => {
        if (!errorMessage) {
            return;
        }
        errorMessage.hidden = false;
    };

    const clearErrors = () => {
        if (errorsList) {
            errorsList.textContent = '';
            errorsList.hidden = true;
        }
        if (errorMessage) {
            errorMessage.hidden = true;
        }
        $form.find('.error').removeClass('error');
        $form.find('[data-errors]').each(node => {
            node.textContent = '';
            node.hidden = true;
        });
    };

    const getErrorNames = (errors, prefix = '') => Object.keys(errors || {}).reduce((carry, key) => {
        if (typeof errors[key] === 'object' && !Array.isArray(errors[key])) {
            return {
                ...carry,
                ...getErrorNames(errors[key], key)
            };
        }
        const name = prefix ? `${prefix}[${key}]` : key;
        return {
            ...carry,
            [name]: errors[key]
        };
    }, {});

    const showErrors = (errors = []) => {

        clearErrors();

        const errorsByName = getErrorNames(errors);
        const errorNames = Object.keys(errorsByName);

        if (!errorNames.length) {
            showErrorMessage();
            return;
        }

        errorNames.forEach(name => {
            const input = $form.find(`[data-input="${name}"]`).get(0);
            if (input) {
                input.classList.add('error');
            }
            const errorsDiv = $form.find(`[data-errors="${name}"]`).get(0);
            if (errorsDiv) {
                errorsDiv.hidden = false;
                $(errorsDiv).html([].concat(errorsByName[name]).join('<br/>'));
            }
        });

        if (errorsList) {
            const html = errorNames.reduce((carry, name) => {
                const input = $form.find(`[data-input="${name}"]`).find('input,textarea,[aria-haspopup="listbox"]').get(0);
                if (!input) {
                    return carry;
                }
                return `${carry}<li><a href="#${input.id}" class="link-alt">${errorsByName[name]}</a></li>`;
            }, '');
            if (html) {
                errorsList.hidden = false;
                $(errorsList).html(html);
            }
        }

        const firstErrorInput = $form.find('[data-input].error').find('input:not([type="hidden"]),textarea,select,button[aria-haspopup="listbox"]').get(0);
        if (firstErrorInput) {
            firstErrorInput.focus();
        }

    };

    const disableSubmit = () => {
        if (submitBtn) {
            submitBtn.setAttribute('aria-disabled', 'true');
        }
    };

    const enableSubmit = () => {
        if (submitBtn) {
            submitBtn.removeAttribute('aria-disabled');
        }
    };

    const onSubmit = e => {

        e.preventDefault();

        if (isSubmitting || !submitBtn) {
            return;
        }

        isSubmitting = true;
        disableSubmit();

        const data = serialize($form.get(0));
        const url = window.location.href.split('?')[0].split('#')[0];

        request
            .post(url)
            .accept('application/json')
            .send(data)
            .then(({ status, body }) => {
                if (status !== 200) {
                    throw new Error();
                }
                const {
                    success, errors = [], receipt = ''
                } = body || {};
                clearErrors();
                if (success) {
                    showReceipt(receipt);
                } else {
                    showErrors(errors);
                }
            })
            .catch(error => {
                console.error(error);
                showErrors();
            })
            .then(() => {
                isSubmitting = false;
                enableSubmit();
            });
    };

    const init = () => {
        $form.on('submit', onSubmit);
    };

    const destroy = () => {
        $form.off('submit', onSubmit);
    };

    return {
        init,
        destroy
    };
};
