import gsap from 'gsap';
import Viewport from '../core/Viewport';

export default el => {

    // const logo = el.querySelector('[data-logo]');
    //
    // const tl = gsap.timeline({
    //     paused: true
    // })
    //     .to(logo.firstElementChild, {
    //         scale: 1, ease: 'none', transformOrigin: 'left top'
    //     });
    //
    // const onScroll = () => {
    //
    //     const { top } = logo.getBoundingClientRect();
    //     const offset = logo.offsetTop;
    //     let progress = ((offset - top) / offset);
    //     if (progress < 0) {
    //         progress = 0;
    //     } else if (progress > 1) {
    //         progress = 1;
    //     }
    //     tl.progress(progress);
    //
    //     // const { height } = el.getBoundingClientRect();
    //     // const { top, height: logoHeight } = logo.getBoundingClientRect();
    //     // const offset = logo.offsetTop;
    //     // let progress = (offset - top) / (height - logoHeight);
    //     // if (progress < 0) {
    //     //     progress = 0;
    //     // } else if (progress > 1) {
    //     //     progress = 1;
    //     // }
    //     // tl.progress(progress);
    // };
    //
    // const init = () => {
    //     Viewport.on('resize', onScroll);
    //     Viewport.on('scroll', onScroll);
    //     logo.hidden = false;
    //     onScroll();
    // };
    //
    // const destroy = () => {
    //     Viewport.off('resize', onScroll);
    //     Viewport.off('scroll', onScroll);
    // };
    //
    // return {
    //     init,
    //     destroy
    // };

};
