import gsap from 'gsap';
import $ from '../core/Dom';

const loadAnim = require('bundle-loader?lazy&name=technology.anim.min!../vendor/technology.anim.min.js');

export default el => {

    const $el = $(el);
    const animEl = $el.find('[data-anim]').get(0);

    const chapters = [{
        index: 0,
        duration: 3
    }, {
        index: 1,
        duration: 6
    }, {
        index: 2,
        duration: 7
    }, {
        index: 3,
        duration: 15
    }];

    let anim = null;
    let animObserver;
    let interval;
    let currentChapter = -1;

    const destroyInterval = () => {
        if (!interval) {
            return;
        }
        clearInterval(interval);
        interval = null;
    };

    const createInterval = (callback, seconds) => {
        destroyInterval();
        interval = setInterval(callback, seconds * 1000);
    };

    const resumeAnim = () => {
        if (!anim || !anim.isPaused()) {
            return;
        }
        anim.setPaused(false);
    };

    const pauseAnim = () => {
        if (!anim) {
            return;
        }
        if (!anim.isPaused()) {
            anim.setPaused(true);
        }
    };

    const nextChapter = () => {
        if (!anim || anim.isPaused() || document.hidden) {
            return;
        }
        const prevChapter = currentChapter;
        currentChapter = currentChapter < chapters.length - 1 ? currentChapter + 1 : 0;
        const chapter = chapters[currentChapter];
        if (prevChapter > 0 && currentChapter === 0) {
            gsap.timeline()
                .to(animEl, {
                    opacity: 0,
                    duration: 0.5
                })
                .add(() => {
                    anim.setChapter(chapter.index);
                    createInterval(nextChapter, chapter.duration);
                })
                .to(animEl, {
                    opacity: 1,
                    duration: 0.3,
                    delay: 1.5,
                    ease: 'Cubic.easeIn'
                });
        } else {
            gsap.to(animEl, { opacity: 1, duration: 0.3 });
            anim.setChapter(chapter.index);
            createInterval(nextChapter, chapter.duration);
        }
    };

    const onAnimObserve = ([{isIntersecting}]) => {
        if (!isIntersecting) {
            pauseAnim();
            return;
        }
        if (anim === null) {
            anim = false;
            // Init the animation
            loadAnim(() => {
                try {
                    anim = window.solutionseeker.create(animEl);
                    gsap.fromTo(animEl, {
                        opacity: 0
                    }, {
                        opacity: 1,
                        duration: 0.3,
                        onStart() {
                            nextChapter();
                        }
                    });
                } catch (error) {
                    console.error(error);
                }
            });
        } else {
            resumeAnim();
        }
    };

    const init = () => {
        animObserver = new IntersectionObserver(onAnimObserve);
        animObserver.observe(animEl);
    };

    const destroy = () => {
        if (animObserver) {
            animObserver.disconnect();
        }
        destroyInterval();
    };

    return {
        init,
        destroy
    };

};
