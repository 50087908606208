/** global ENV */
import gsap from 'gsap';
import $ from './core/Dom';
import Viewport from './core/Viewport';
import ViewportHeight from './lib/ViewportHeight';
import Components from './core/Components';
import Dispatch from './core/Dispatch';
import lazySizes from './lib/lazysizes';

import { DRILLDOWN_BEFORE_OPEN, DRILLDOWN_AFTER_CLOSE } from './lib/events';
import { lockScroll, unlockScroll } from './lib/helpers';

gsap.defaults({ overwrite: 'auto' });

const init = () => {

    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    lazySizes.init();

    const supportsOverscrollBehavior = () => window.CSS && CSS.supports('overscroll-behavior', 'none');

    Dispatch.on(DRILLDOWN_BEFORE_OPEN, () => {
        document.documentElement.classList.add('modal-open');
        if (!supportsOverscrollBehavior()) {
            lockScroll();
        }
    });

    Dispatch.on(DRILLDOWN_AFTER_CLOSE, () => {
        document.documentElement.classList.remove('modal-open');
        if (!supportsOverscrollBehavior()) {
            unlockScroll();
        }
    });

    $('body').on('click', 'a[data-anchor]', e => {
        const id = e.triggerTarget.href.split('#').pop();
        const target = $(`#${id}`).get(0);
        if (!target) {
            return true;
        }
        e.preventDefault();
        Viewport.scrollTo(target);
        return false;
    });

};

require('doc-ready')(init);
