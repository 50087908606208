/* global COMPONENTS_PATH */

/*
 DESCRIPTION:

 Module for parsing, loading and initializing components/classes from both markup and code.
 This module parses the DOM looking for component declarations in the following format:

 <div data-component="SomeComponent" data-component-props="someoptionalJsonData"></div>

 The module also exposes a public API:
 // "parse" lets us parse and load components from markup for a css-selector OR html-element
 Components.parse(selectorOrElement)

 // "load" lets us load modules from code (not markup) for a css-selector OR html-element, with a component-path and optional component-props
 Components.load(selectorOrElement, componentPath, componentProps, componentState)

 // "destroy" lets us call the destroy method on our modules (or sub-module) for a css-selector, html-element OR module-path
 Components.destroy(selectorOrElementOrComponent)

 */

import $ from './Dom';

const COMPONENT_SELECTOR = '[data-component]';
const COMPONENT_BOOTED_ATTRIBUTE = 'data-component-booted';

// Keeps track of component instances (this'll be a map where the module names are keys)
let componentMap = {};

// Require modules
const getContext = () => require.context(COMPONENTS_PATH, true, /\.js$/);
const componentModules = {};
const context = getContext();
context.keys().forEach(key => {
    componentModules[key] = context(key);
});

// Thin wrapper for Dom.js - returns DOM elements based on selector
const getElements = selectorOrElement => $(selectorOrElement);

// Initializes a component based on a DOM element (needs the `data-component` attribute if `componentPath` is not passed in)
const createComponent = ({el, componentPath = `./${el.getAttribute('data-component')}.js`, componentProps = null, componentState = null}) => {

    // Get the module
    const module = (componentModules[componentPath] || {}).default || null;

    if (!module) {
        console.warn(`Component ${componentPath} not found. Check that the file exists, and that it exposes a default export`);
        return null;
    }

    if (el.getAttribute(COMPONENT_BOOTED_ATTRIBUTE) !== null) {
        // This component has already booted
        return null;
    }

    if (!document.body.contains(el)) {
        // The node is no longer in the DOM - probably it's a nested component and the parent component has already replaced it's DOM node – no worries, the component has been created, so just return
        return null;
    }

    el.setAttribute(COMPONENT_BOOTED_ATTRIBUTE, '');

    // We need a copy of the unmutated element for HMR
    const originalEl = el.cloneNode(true);
    const props = componentProps || JSON.parse(el.getAttribute('data-component-props') || null);
    const state = componentState || JSON.parse(el.getAttribute('data-component-state') || null);

    // Create the component
    const isObject = typeof module === 'object';
    const Construct = !isObject ? module : (() => module);
    let component;
    try {
        component = new Construct(el, props, state);
    } catch (error) {
        component = Construct(el, props, state);
    }

    if (!component) {
        return null;
    }

    // Support the "revealing API" pattern
    if (component.init) {
        component.init(el, props, state);
    }

    // Get an ID reference for this component
    const componentId = `vrsg_${Object.values(componentMap[componentPath] || {}).length + 1}`;

    // Some components (Vue.js) overwrite the el in the DOM, so wanna update our `el` DOM node reference to avoid issues with that
    el = $(component.el || component.$el || el).get(0);
    el._vrsg_id = componentId;

    // Store a reference to the component in the component map (used for HMR)
    componentMap[componentPath] = Object.assign(componentMap[componentPath] || {}, {
        [componentId]: {
            component,
            originalEl,
            el
        }
    });

    console.info('Component created', { path: componentPath, props, state });

    // Initialise nested components
    parseComponents(el);

    return component;

};

const parseComponents = (selectorOrElement = 'body') => {

    const root = getElements(selectorOrElement);
    const elements = root.find(`${COMPONENT_SELECTOR}:not([${COMPONENT_BOOTED_ATTRIBUTE}])`);

    elements.each(el => {
        createComponent({ el });
    });

};

const loadComponents = (selectorOrElement, componentPath, componentProps, componentState) => {
    $(selectorOrElement).each(el => {
        createComponent({ el, componentPath, componentProps, componentState });
    });
};

const destroyComponents = (selectorOrElementOrComponent, restoreOriginalEl = true) => {

    if (!selectorOrElementOrComponent) {

        // Destroy all component instances
        const instances = Object.values(componentMap)
            .reduce((carry, componentInstances) => carry.concat(Object.values(componentInstances)), []);

        instances.forEach(component => destroyComponents(component));

    } else {

        const componentsToDestroy = [];

        // Is it a component, a selector or an element?
        if (selectorOrElementOrComponent === Object(selectorOrElementOrComponent) && selectorOrElementOrComponent.component) {

            // It's probably a component!
            componentsToDestroy.push(selectorOrElementOrComponent);

        } else {

            // It's gotta be a selector or element – let's lean on Dom.js :P
            let elements = [];
            const root = $(selectorOrElementOrComponent);
            root.each(rootEl => {
                elements = elements.concat($(rootEl).find(`${COMPONENT_SELECTOR}[${COMPONENT_BOOTED_ATTRIBUTE}]`).get());
                if ($(rootEl).data('component') && $(rootEl).attr(COMPONENT_BOOTED_ATTRIBUTE) !== null) {
                    elements = [rootEl].concat(elements);
                }
            });
            elements.forEach(el => {
                const component = componentMap[`./${el.getAttribute('data-component')}.js`][(el._vrsg_id || '')] || null;
                if (!component) {
                    return;
                }
                componentsToDestroy.push(component);
            });
        }

        componentsToDestroy.forEach(componentToDestroy => {

            let { component, el, originalEl } = componentToDestroy;

            if (!component) {
                return;
            }

            const componentId = el ? el._vrsg_id || null : null;
            const componentPath = el ? `./${el.getAttribute('data-component')}.js` : null;
            if (componentId && componentPath && !!(componentMap[componentPath][componentId] || null)) {
                delete componentMap[componentPath][componentId];
            }

            // Restore the original element?
            el = $(component.el || component.$el || el).get(0);
            if (restoreOriginalEl && el && originalEl) {
                originalEl.removeAttribute('data-component-booted');
                $(originalEl).find('.lazyloading').removeClass('lazyloading').addClass('lazyload');
                el.parentNode.replaceChild(originalEl, el);
            } else if (el) {
                el.removeAttribute('data-component-booted');
            }

            // Destroy the component
            if (component.destroy && typeof component.destroy === 'function') {
                component.destroy();
            } else if (component.$destroy && typeof component.$destroy === 'function') {
                // Vue.js
                component.$destroy();
            }

        });

    }

};

/*
 *   Hot module replacement for components
 *
 */
if (module.hot) {

    module.hot.accept(context.id, () => {

        // Refresh module context
        const newContext = getContext();

        // Get updated modules
        const newModules = newContext.keys()
            .map(key => [key, newContext(key)])
            .filter(module => componentModules[module[0]] !== module[1]);

        newModules.forEach(module => {

            // Replace existing module
            const componentPath = module[0];
            componentModules[componentPath] = module[1];

            // Re-initialize and replace instances
            const instances = componentMap[componentPath] || {};
            delete componentMap[componentPath];

            Object.values(instances).forEach(({ component, originalEl, el }) => {

                if (component.props && component.props.hot === false) {
                    location.reload();
                    return false;
                }

                // Get the current component element. This works for Vue components also, because they expose an attribute $el at the root level
                el = $(component.el || component.$el || el).get(0);

                // Get current props and state from component
                const { props: componentProps = null, state: componentState = null } = component;

                // Get nested components
                const nestedComponents = [];
                if (el) {
                    const nestedComponentEls = $(el).find('[data-component]').get();
                    nestedComponentEls.forEach(el => {
                        const componentId = el._vrsg_id || null;
                        if (!componentId) {
                            return;
                        }
                        const nestedComponent = componentMap[`./${el.getAttribute('data-component')}.js`][componentId] || instances[componentId] || null;
                        if (!nestedComponent || !nestedComponent.component) {
                            return;
                        }
                        nestedComponents.push(nestedComponent.component);
                    });
                }

                const componentsToDestroy = nestedComponents.concat(component);

                // Call the `beforeDispose` lifecycle hook
                componentsToDestroy.forEach(component => {
                    if (component.beforeDispose && typeof component.beforeDispose === 'function') {
                        component.beforeDispose();
                    }
                });

                // Destroy all the things
                componentsToDestroy.forEach(component => {
                    if (component.destroy && typeof component.destroy === 'function') {
                        component.destroy();
                    } else if (component.$destroy && typeof component.$destroy === 'function') {
                        // Vue.js
                        component.$destroy();
                    }
                });

                // Restore original element in DOM (this will replace nested components as well)
                if (el && originalEl) {
                    $(originalEl).find('.lazyloading').removeClass('lazyloading').addClass('lazyload'); // Hotfix for lazysizes
                    el.parentNode.replaceChild(originalEl, el);
                    el = originalEl;
                }

                // Create the new component instance
                el.removeAttribute(COMPONENT_BOOTED_ATTRIBUTE);
                createComponent({
                    el, componentPath, componentProps, componentState
                });

            });

        });

    });
}


// Expose public API
export default {
    init: parseComponents,
    parse: parseComponents,
    load: loadComponents,
    destroy: destroyComponents
};
